html {
  color: #e04778;
}

body {
  margin: 0;
  font-family: "Arial Rounded MT Bold", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Arial Rounded MT Bold", sans-serif;
}
